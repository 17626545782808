@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

*, *:after, *:before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body, html {
    border: 0px;
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: auto;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    background-color: #FFFFFF;
}

a {
    color: #000;
    text-decoration: none;
    transition: color .2s ease-in;
}

a:hover {
    color: #761f20;
}

.topo {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100px;
    box-shadow: 1px 1px 3px #1a1a1a;
    position: relative;
    z-index: 333;
}

.logo {
    width: 22%;
    height: inherit;
    margin-top: 20px;
    margin-right: 1%;
}

.logo img {
    width: 240px;
    max-width: 300px;
}

.menu {
    position: relative;
    z-index: 999;
    width: 100%;
    margin-top: 33px;
    list-style: none;
    float: right;
}

.menu li {
    font-size: 85%;
    position: relative;
    float: left;
    margin-right: 10px;
}

.menu li {
    color: #000;
    text-decoration: none;
    padding: 10px;
    display: block;
    border-radius: 8px;
}

.menu li:hover {
    background: #F1F1F1;
    color: #000;
    cursor: pointer;
}

.menu li ul {
    min-width: 150px;
    position: absolute;
    top: 36px;
    left: 7px;
    background-color: #fff;
    border: 1px solid #c0c0c0;
    display: none;
}

.menu li:hover ul,
.menu li.over ul {
    display: block;
    box-shadow: 2px 2px 3px #CCC;
    border-radius: 8px;
}

.menu li ul li {
    display: block;
    width: 100%;
}

.menu .icon {
    padding-top: 10px;
}

.bloco-conteudo {
    max-width: 85%;
    min-height: 470px;
    margin: 0 auto 60px;
}

h1.topicos {
    width: 100%;
    height: 50px;
    text-align: center;
    font-size: 40px;
    color: #8d8884;
}

.conteudo {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 40px;
}

.noticia-unique {
    width: 30%;
    height: auto;
    float: left;
    margin: 1%;
}

.noticia-unique h3 {
    width: 97%;
    min-height: 60px;
    padding-left: 10px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    letter-spacing: .1px;
    font-weight: 500;
    line-height: 1em;
}

.noticia-unique h4 {
    width: 60%;
    min-height: 30px;
    float: left;
    padding: 10px 0 0 10px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 90%;
    letter-spacing: .1px;
    font-weight: 500;
    line-height: 1em;
}

.noticia-unique h5 {
    width: 40%;
    min-height: 30px;
    color: #85A353;
    float: right;
    padding: 8px 15px 0 0;
    text-align: right;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.noticia-unique img {
    width: 100%;
    height: 240px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 2px 2px 3px #CCC;
}

.content {
    max-width: 80%;
    min-height: 60vh;
    margin: 0 auto 80px;
    margin-top: 70px;
}

.noticia h1 {
    text-align: right;
    line-height: 1em;
    margin-bottom: 50px;
    margin-right: -20px;
    font-size: 4.8em;
    font-weight: 300;
    letter-spacing: -1px;
}

.noticia h1:before {
    content: "";
    margin-right: -100px;
    top: 10px;
    width: 20vw;
    height: 1px;
    background: #761f20;
    display: inline-block;
    position: relative;
}

.noticia h1.internal {
    text-align: right;
    line-height: 1.1em;
    margin-bottom: 35px;
    margin-right: -20px;
    font-size: 2.8em;
    font-weight: 300;
    letter-spacing: -1px;
}

.noticia h1 > small {
    font-size: 55%;
    padding-top: -50px;
    padding-left: 15px;
    color: #535b67;
}

.noticia h4 {
    font-weight: lighter;
    font-size: 90%;
    color: #2F353E;
    line-height: 1.4em;
    text-align: justify;
    margin-bottom: 30px;
}

.noticia h4 > p {
    margin-bottom: 20px;
}

.noticia h6 {
    text-align: right;
    font-weight: lighter;
    font-size: 90%;
    color: #3c763d;
    margin: -30px -15px 45px 0;
}

.noticias__list {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 40px auto 0;
}

.noticias__list h2 {
    min-height: 75px;
    text-align: left;
    font-size: 1.3em;
    font-weight: 400;
}

.noticias__list h4 {
    text-align: left;
    font-weight: lighter;
    font-size: 90%;
    color: #2F353E;
    line-height: 1.2em;
    margin-bottom: 0;
}

.noticias__list h5 {
    margin: 0;
}

.noticias__list a {
    width: 160px;
    margin: 0 20px 0 0;
}

.noticias__list a > img {
    width: 160px;
    height: 110px;
    border-radius: 5px;
    transition: all .2s ease-in;
}

.noticias__list a > img:hover {
    box-shadow: 1px 1px 3px #2F353E;
}

.repositorio__list {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 40px auto 0;
    border-bottom: 1px solid #CCC;
}

.repositorio__list h2 {
    margin-bottom: 7px;
    text-align: left;
    font-size: 1.3em;
    font-weight: 400;
}

.repositorio__list h4 {
    text-align: left;
    font-weight: lighter;
    font-size: 90%;
    color: #2F353E;
    line-height: 1.2em;
    margin-bottom: 0;
}

.repositorio__list h5 {
    margin: 0;
}

.repositorio__list a {
    width: 160px;
    margin: 0 20px 0 0;
}

.repositorio__list a > img {
    width: 160px;
    height: 110px;
    border-radius: 5px;
    transition: all .2s ease-in;
}

.repositorio__list a > img:hover {
    box-shadow: 1px 1px 3px #2F353E;
}

.fiped__list {
    width: 95%;
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 40px auto 0;
}

.fiped__list h2 {
    min-height: 75px;
    text-align: left;
}

.fiped__list h4 {
    text-align: left;
    font-weight: lighter;
    font-size: 90%;
    color: #2F353E;
    line-height: 1.2em;
    margin-bottom: 0;
}

.fiped__list a {
    width: 18%;
    margin: 0 20px 0 0;
}


.fiped__list a > img {
    width: 160px;
    height: 110px;
    border-radius: 5px;
    transition: all .2s ease-in;
}

.fiped__list a > img:hover {
    box-shadow: 1px 1px 3px #2F353E;
}

.pagination {
    width: 100%;
    text-align: center;
    margin: 50px 0;
}

.pagination li{
    list-style: none;
    display: inline;
    margin: 10px;
    border-radius: 5px;
    background-color: #ddc9c1;
    color: white;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s linear;
}

.pagination li:hover{
    cursor: pointer;
    background-color: #a79c96
}

.videos__list {
    width: 46%;
    min-height: 410px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 30px 4% 0 auto;
    float: left;
    padding-bottom: 50px;
}

.videos__list h2 {
    font-size: 100%;
    height: 50px;
    text-align: left;
}

.videos__list h5 {
    font-size: 80%;
    font-weight: lighter;
}

.videoWrapper {
    width: 100%;
    position: relative;
    padding-bottom: 30.25%; /* 16:9 */
    padding-top: 200px;
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.conteudo-imagens {
    width: 90%;
    height: auto;
    float: left;
    margin: 60px auto 0;
}

.conteudo-imagens img {
    width: 21%;
    height: 150px;
    float: left;
    border-radius: 10px;
    margin: 1% 2% 20px;
    box-shadow: 2px 2px 5px #666;
}

.conteudo-imagens img:hover {
    cursor: pointer;
}

.linha {
    background-color: #dacfcb;
    width: 120px;
    height: 15px;
    margin: -20px auto 0;
}

.faca-parte {
    width: 100%;
    height: 370px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    box-sizing: border-box;
    padding: 60px 120px;
    background: url('../images/bg-associe.jpg') no-repeat;
    background-size: cover;
    margin-bottom: 70px;
}

.faca-parte h1 {
    font-size: 3.5em;
    padding-bottom: 0.5em;
    letter-spacing: -0.5px;
}

.faca-parte button {
    width: 250px;
    height: 60px;
    margin-right: 150px;
    border-radius: 8px;
    font-size: 1.2em;
    letter-spacing: -0.8px;
    color: #FFF;
    border: none;
    box-shadow: 2px 2px 3px #000;
    background: linear-gradient(#533f39, #c9b2a5);
}

.faca-parte button a {
    color: #FFF;
}

.faca-parte button a:hover {
    color: #1f130f;
}

.faca-parte button:hover {
    cursor: pointer;
}

.faca-parte h6 {
    width: 250px;
    margin: 10px 50px 0 0;
    border-radius: 8px;
    font-size: 0.8em;
    color: #FFF;
    text-shadow: 2px 2px 3px #000;
}

#maps {
    width: 100%;
    height: 360px;
    margin: 60px 0 0 0;
}

#footer {
    width: 100%;
    min-height: 100px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: url('../images/bg-final.jpg') no-repeat;
    background-size: cover;
    padding: 45px 80px;
}

.noticias-final {
    width: 35%;
    font-size: 85%;
    height: auto;
    margin-left: 70px;
    margin-right: 70px;
    color: #FFF;
}

.noticias-final h2 {
    text-align: left;
    margin-bottom: 20px;
    color: #FFF;
}

.noticias-final h3 {
    text-align: left;
    margin-bottom: 20px;
    color: #FFF;
    transition: color .2s ease;
}

.noticias-final a h3:hover {
    color: #363636;
}

h4.local {
    text-align: right;
    margin: -55px -15px 50px 0;
}

.contatos {
    width: 35%;
    height: auto;
    margin-left: 50px;
    color: #FFF;
}

.contatos h2 {
    text-align: left;
    margin-bottom: 20px;
    color: #FFF;
}

.contatos h3 {
    text-align: left;
    font-weight: normal;
    font-size: 90%;
    margin-bottom: 10px;
    color: #FFF;
}

.contatos h3 > a {
    text-align: left;
    color: #FFF;
    transition: color .2s ease;
}

.contatos h3 > a:hover {
    color: #363636;
}

.documentos {
    padding: 40px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: lighter;
    border-bottom: 1px solid #DDD;
}

.documentos h2 {
    margin-bottom: 10px;
    font-size: 90%;
    font-weight: bold;
}

.documentos h5 {
    margin-bottom: 10px;
    font-size: 80%;
    font-weight: lighter;
}

.documentos img {
    margin: 0 20px;
}

.list-associados {
    padding: 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: lighter;
    border-bottom: 1px solid #DDD;
}

.associados h1 {
    text-align: right;
    line-height: 1em;
    margin-bottom: 35px;
    margin-right: -20px;
    font-size: 4.8em;
    font-weight: 300;
    letter-spacing: -1px;
}

.associados h1:before {
    content: "";
    margin-right: -100px;
    top: 10px;
    width: 20vw;
    height: 1px;
    background: #761f20;
    display: inline-block;
    position: relative;
}

.associados h2 {
    font-weight: bold;
    font-size: 100%;
    color: #2F353E;
    line-height: 1em;
    text-align: justify;
    padding-top: 10px;
    margin-bottom: 5px;
}

.associados h4 {
    text-align: left;
    font-weight: lighter;
    font-size: 70%;
    color: #2F353E;
    line-height: 1.2em;
}

.associados img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    margin: 0 25px;
    margin-top: 10px;
}

form {
    display: flex;
    flex-direction: column;
    padding: 4% 0 0 0;
}
input[type='text'], input[type='email'], input[type='tel'], textarea {
    padding: 2%;
    margin: 1% 0;
    border: 1.3px dashed #000;
    border-radius: 5px;
    font-size: 0.9em;
    outline: none;
    transition: all .3s ease-in;
}
input:focus, textarea:focus {
    background-color: #F1F1F1;
}

input::placeholder, textarea::placeholder {
    font-weight: 300;
    font-size: 1.4em;
}
input:nth-last-child(1) {
    border: none;
}
textarea {
    height: 20vh;
}
input[type="submit"] {
    height: 60px;
    border-radius: 10px;
    transition: background-color .3s;
    background-color: #CCC;
}

input[type="submit"]:hover {
    cursor: pointer;
    background-color: #EEE;
}

input[type="image"] {
    width: 230px;
    height: auto;
}

.copyright {
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    background-color: #716664;
    color: #FFF;
    font-weight: lighter;
    padding: 25px 80px;
}

.fsenaweb {
    float: right;
    font-weight: bold;
    font-size: 80%;
    margin-top: 5px;
    letter-spacing: -1px;
}

.fsenaweb:after {
    content: ".me";
    font-weight: lighter;
    letter-spacing: -1px;
}

.fsenaweb a {
    color: #FFF;
    transition: color .3s;
}

.fsenaweb a:hover {
    color: #AAA;
}

.erro404 {
    width: 60%;
    height: 40vh;
    margin: 100px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    color: #716664;
}

.erro404 h1 {
    font-size: 6em;
    line-height: 1em;
    letter-spacing: -3px;
}

.erro404 h2 {
    font-weight: lighter;
}

/* ***************** SLIDE **********************8 */
.slide-container {
    width: 100%;
    position: relative;
    margin: auto auto 60px;
    z-index: 100;
}

h3 {
    text-align: center;
}

.each-slide > div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background-size: cover;
    height: 470px;
}

.each-slide span {
    width: 100%;
    padding: 25px;
    font-size: 90%;
    color: #FFF;
    background: rgba(255, 0, 0, 0.2);
    text-align: left;
}

.each-fade {
    display: flex;
}

.each-fade .image-container {
    width: 75%;
    overflow: hidden;
}

.each-fade .image-container img {
    width: 100%;
}

.each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
}

#menuToggle {
    display: none;
}

/* FORMULÁRIO */

.formulario form {
    margin: 0;
    padding: 0;
}

.formulario hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin: 10px 0;
}

.formulario form label {
    width: 100%;
    font-size: 0.9em;
    color: #333;
    font-weight: 500;
    float: left;
    padding: 8px;
}

.formulario .input-group{
    display: table;
    border-collapse: collapse;
    width:100%;
    margin: 0 8px;
}

.formulario .input-group-area{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.formulario .form-control input {
    width: 100%;
}

.formulario .form-control-sd {
    width: 15.5%;
}

.formulario .form-control-xs {
    width: 33.3%;
}

.formulario .form-control-md {
    width: 66.5%;
}

.formulario .form-control-lg {
    width: 100%;
}

.formulario .input-group input[type="text"] {
    display: block;
    border: 1px solid #d1d1d1;
    padding: 10px;
}

.formulario .input-group select {
    display: block;
    width: 100%;
    border: 1px solid #d1d1d1;
    padding: 11px;
    background-color: #FFF;
    border-radius: 5px;
}

.formulario .input-group input[type="text"]:focus, input[type="email"]:focus {
    outline: none;
}

.formulario .input-group input[type="text"]::placeholder {
    font-size: 90%;
}

.formulario .radio {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
}

.formulario .radio input {
    margin-right: 10px;
}

.formulario .error {
    display: block;
    width: 100%;
    height: auto;
    padding: 4px;
    border-radius: 3px;
    margin-top: 5px;
    color: #393636;
    background-color: rgba(227, 48, 37, 0.3); /* 227 48 37 */
}

.btn {
    padding: 12px 25px;
    border: none;
    border-radius: 5px;
}

.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}
.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: #286090;
    border-color: #122b40;
}

.btn-primary:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
    cursor: pointer;
}



@media screen and (max-width: 700px) {
    .topo {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100px;
        box-shadow: 1px 1px 3px #1a1a1a;
        position: relative;
        z-index: 333;
    }

    .logo {
        width: 100%;
        height: inherit;
        text-align: center;
        margin-top: 25px;
        margin-right: 0;
    }

    .logo img {
        width: 240px;
        max-width: 280px;
    }

    .menu {
       display: none;
    }

    .linha {
        background-color: #dacfcb;
        width: 120px;
        height: 15px;
        margin: -20px auto 0;
    }

    .bloco-conteudo {
        max-width: 90%;
        min-height: 470px;
        margin: 0 auto 30px;
    }

    h1.topicos {
        width: 100%;
        height: 50px;
        text-align: center;
        font-size: 40px;
        color: #8d8884;
    }

    .conteudo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-top: 40px;
    }

    .noticia-unique {
        width: 95%;
        height: auto;
        float: left;
        margin: 1% 1% 50px;
    }

    .faca-parte {
        width: 100%;
        height: 230px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 40px;
        background: url('../images/bg-associe.jpg') no-repeat;
        background-size: cover;
        margin-bottom: 50px;
    }

    .faca-parte h1 {
        font-size: 1.5em;
        padding-bottom: 1em;
        letter-spacing: -0.5px;
    }

    .faca-parte button {
        width: 200px;
        height: 60px;
        margin-right: 0;
        border-radius: 8px;
        font-size: 1em;
        letter-spacing: -0.8px;
        color: #FFF;
        border: none;
        box-shadow: 2px 2px 3px #000;
        background: linear-gradient(#533f39, #c9b2a5);
    }

    .faca-parte button a {
        color: #FFF;
    }

    .faca-parte h6 {
        width: 250px;
        margin: 10px -70px 0 0;
        border-radius: 8px;
        font-size: 1em;
        color: #FFF;
        text-shadow: 2px 2px 3px #000;
    }

    .conteudo-imagens {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 95%;
        height: auto;
        float: left;
        margin: 80px auto 0;
    }

    .conteudo-imagens img {
        width: 46%;
        height: 150px;
        float: left;
        border-radius: 10px;
        margin: 1% 2% 20px;
        box-shadow: 2px 2px 5px #666;
    }

    /* MENU MOBILE */

    nav {
        position: absolute;
    }

    #menuToggle {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 40px;
        left: 15px;
        z-index: 1;
        -webkit-user-select: none;
        user-select: none;
    }

    #menuToggle input
    {
        display: flex;
        width: 40px;
        height: 32px;
        position: absolute;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
    }

    #menuToggle span
    {
        display: flex;
        width: 29px;
        height: 2px;
        margin-bottom: 5px;
        position: relative;
        background-color: #000;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 5px 0;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        opacity 0.55s ease;
    }

    #menuToggle span:first-child
    {
        transform-origin: 0 0;
    }

    #menuToggle span:nth-last-child(2)
    {
        transform-origin: 0 100%;
    }

    #menuToggle input:checked ~ span
    {
        opacity: 1;
        transform: rotate(45deg) translate(-3px, -1px);
        background: #36383F;
    }
    #menuToggle input:checked ~ span:nth-last-child(3)
    {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    #menuToggle input:checked ~ span:nth-last-child(2)
    {
        transform: rotate(-45deg) translate(0, -1px);
    }

    #menu
    {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: fixed;
        width: 72vw;
        min-height: 110%;
        padding: 50px 35px 0 60px;
        margin: -50px 0 0 -50px;
        background-color: #F5F6FA;
        box-shadow: 1px 1px 10px #1a1a1a;
        -webkit-font-smoothing: antialiased;
        transform: translate(-100%, 0);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }

    .list li {
        float: left;
        transform: translateX(10px);
        animation: FadeIn .3s linear backwards;
    }

    @keyframes FadeIn {
        0% {
            opacity: 0;
            transform: translateX(-20px);
        }
        30% {
            opacity: 0.3;
            transform: translateX(-10px);
        }
        50% {
            opacity: 0.5;
            transform: translateX(0px);
        }

        100% {
            opacity: 1;
            transform: translateX(10px);
        }
    }

    .list li:nth-child(1) { animation-delay: .2s }
    .list li:nth-child(2) { animation-delay: .5s }
    .list li:nth-child(3) { animation-delay: .8s }
    .list li:nth-child(4) { animation-delay: 1.1s }
    .list li:nth-child(5) { animation-delay: 1.4s }
    .list li:nth-child(6) { animation-delay: 1.7s }
    .list li:nth-child(7) { animation-delay: 2s }
    .list li:nth-child(8) { animation-delay: 2.3s }
    .list li:nth-child(9) { animation-delay: 2.6s }


    #menu li
    {
        list-style: none;
        padding: 5px;
        font-size: 1.1em;
        font-weight: 400;
    }

    #menu .sub__menu
    {
        width: 100%;
        list-style: none;
        padding: 6px;
        margin-left: 5px;
        border-bottom: 0.3px solid #CCC;
        font-size: 77%;
        font-weight: 300;
    }

    #menu a {
        color: #000;
        text-decoration: none;
    }

    #menuToggle input:checked ~ ul
    {
        transform: translateX(10px);
    }

    /* MENU MOBILE */



    /* ***************** SLIDE **********************8 */
    .slide-container {
        width: 100%;
        position: relative;
        margin: auto auto 60px;
        z-index: 100;
    }

    h3 {
        text-align: center;
    }

    .each-slide > div {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        background-size: cover;
        height: 250px;
    }

    .each-slide span {
        width: 100%;
        padding: 20px;
        font-size: 85%;
        color: #FFF;
        background: rgba(255, 0, 0, 0.2);
        text-align: left;
    }

    .each-fade {
        display: flex;
    }

    .each-fade .image-container {
        width: 75%;
        overflow: hidden;
    }

    .each-fade .image-container img {
        width: 10%;
    }

    .each-fade h2 {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        background: #adceed;
    }

    #footer {
        width: 100%;
        min-height: 100px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background: url('../images/bg-final.jpg') no-repeat;
        background-size: cover;
        padding: 5px 8px;
    }

    .noticias-final {
        display: none;
    }

    .contatos {
        display: none;
    }

    .noticia h1 {
        text-align: right;
        line-height: 1.2em;
        margin-bottom: 30px;
        margin-right: 0px;
        font-size: 2em;
        font-weight: 300;
        letter-spacing: -1px;
    }

    .noticia h1:before {
        content: "";
        margin-right: -30px;
        top: 10px;
        width: 30vw;
        height: 1px;
        background: #761f20;
        display: inline-block;
        position: relative;
    }

    .noticia h1.internal {
        text-align: right;
        line-height: 1.3em;
        margin-bottom: 35px;
        margin-right: 0;
        font-size: 1.8em;
        font-weight: 300;
        letter-spacing: -1px;
    }

    .noticia h1 > small {
        font-size: 60%;
        padding-top: 5px;
        padding-left: 0;
        color: #535b67;
        float: right;
    }

    .noticia h6 {
        text-align: right;
        font-weight: lighter;
        font-size: 90%;
        color: #3c763d;
        margin: -30px 0 30px 0;
    }

    h4.local {
        text-align: right;
        margin: 50px 0 20px 0;
    }

    .documentos {
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: lighter;
        border-bottom: 1px solid #DDD;
    }

    .documentos h2 {
        margin-bottom: 10px;
        font-size: 90%;
        font-weight: bold;
    }

    .documentos h5 {
        margin-bottom: 10px;
        font-size: 70%;
        font-weight: lighter;
    }

    .documentos img {
        margin: 0 5px;
    }

    .list-associados {
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: lighter;
        border-bottom: 1px solid #DDD;
    }

    .associados h1 {
        text-align: right;
        line-height: 1em;
        margin-bottom: 35px;
        margin-right: -10px;
        font-size: 2.5em;
        font-weight: 300;
        letter-spacing: -1px;
    }

    .associados h1:before {
        content: "";
        margin-right: -50px;
        top: 10px;
        width: 30vw;
        height: 1px;
        background: #761f20;
        display: inline-block;
        position: relative;
    }

    .associados h2 {
        font-weight: bold;
        font-size: 100%;
        color: #2F353E;
        line-height: 1em;
        text-align: justify;
        padding-top: 5px;
    }

    .associados h4 {
        text-align: left;
        font-weight: lighter;
        font-size: 65%;
        color: #2F353E;
        line-height: 1.2em;
    }

    .associados img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        margin: 0 15px;
        margin-top: 10px;
    }

    .content {
        max-width: 90%;
        min-height: 60vh;
        margin: 0 auto 50px;
        margin-top: 40px;
    }

    .fiped__list {
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 40px auto 0;
    }

    .fiped__list h2 {
        text-align: left;
        margin: 0 0 -5px 0;
    }

    .fiped__list h4 {
        text-align: left;
        font-weight: lighter;
        font-size: 80%;
        color: #2F353E;
        line-height: 1em;
        margin-bottom: 0;
    }

    .fiped__list a {
        width: 35%;
        margin: 0 10px 0 0;
    }


    .fiped__list a > img {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        transition: all .2s ease-in;
    }

    .videos__list {
        width: 96%;
        min-height: 380px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 5px 2% 0 auto;
        float: left;
        padding-bottom: 10px;
    }

    .videoWrapper {
        width: 100%;
        position: relative;
        padding-bottom: 12.25%; /* 16:9 */
        padding-top: 200px;
        height: 0;
    }
    .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .noticias__list {
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 20px auto 0;
    }

    .noticias__list h2 {
        text-align: left;
        font-size: 1.3em;
        font-weight: 500;
        padding-top: 5px;
    }

    .noticias__list h4 {
        text-align: left;
        font-weight: lighter;
        font-size: 85%;
        color: #2F353E;
        line-height: 1.2em;
        margin-bottom: 0;
    }

    .noticias__list a {
        width: 100%;
        margin: 0 20px 0 0;
    }

    .noticias__list a > img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        transition: all .2s ease-in;
    }
}
